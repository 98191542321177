<template>
    <div>
        <b-row v-if="$router.currentRoute.fullPath !== '/my-profile/my-dashboard'">
            <b-col class="mb-3" md="3">
                <user-profile-menu-left page="data" />
            </b-col>
            <b-col class="pl-sm-2" md="9">
                <router-view></router-view>
            </b-col>
        </b-row>
        <router-view v-else></router-view>
    </div>
</template>

<script>
    import {BRow, BCol} from 'bootstrap-vue'
    import userProfileMenuLeft from '@/views/Components/UserProfileMenuLeft'
    export default {
        components: {BRow, BCol, userProfileMenuLeft}
    }
</script>

<style scoped>
.grid-container {
    display: grid;
    grid-template-columns: auto auto;
    background-color: #FFFFFF;
}
.grid-item {
    background-color: #FFFFFF;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
}
</style>