<template>
    <div class="profil-menu">
        <div class="p-1" style="background-color: #72A5D8;">
            <h4 class="text-white m-0" style="font-weight: bold" v-if="$store.state.user.userData">Pozdravljeni, {{$store.state.user.userData.name}}</h4>
        </div>

        <div role="tablist" class="collapse-icon collapse-card collapse-default">
            <b-card no-body v-bind:style="{'background-color': buttonUserData}" @click="navigate('angleData', 'buttonUserData', 'my-data')">
                <b-card-header class="d-block" role="tab"> 
                    <!-- data-toggle="collapse" :aria-expanded="buttonUserData === 'white' ? 'true' : 'false'" -->
                    <b-row align-v="center">
                        <b-col cols="3" align="center">
                            <fa icon="user" size="lg"/>
                        </b-col>
                        <b-col cols="9">
                            <h4 class="mb-0" style="color: black;" :class="{ 'text-blue' : active === 'data'}">MOJI PODATKI</h4>
                        </b-col>
                    </b-row>
                </b-card-header>
            </b-card>
            <b-card no-body v-bind:style="{'background-color': buttonUserOrders}" @click="navigate('angleOrders', 'buttonUserOrders', 'my-orders')">
                <div style="height: 1px; background-color: #E6E6E6;"><hr class="m-0" style="border-top: 1px solid white;"/></div>
                <b-card-header class="d-block" role="tab">
                    <!-- data-toggle="collapse" :aria-expanded="buttonUserOrders === 'white' ? 'true' : 'false'" -->
                    <b-row align-v="center">
                        <b-col cols="3" align="center">
                            <fa icon="shopping-cart" size="lg"/>
                        </b-col>
                        <b-col cols="9">
                            <h4 class="mb-0" style="color: black;" :class="{ 'text-blue' : active === 'orders'}">MOJA NAROČILA</h4>
                        </b-col>
                    </b-row>
                </b-card-header>
            </b-card>
            <b-card no-body v-bind:style="{'background-color': buttonUserCoupons}">
                <div style="height: 1px; background-color: #E6E6E6;"><hr class="m-0" style="border-top: 1px solid white;"/></div>
                <b-card-header class="d-block" role="tab" @click="navigate('angleCoupon', 'buttonUserCoupons', 'my-coupons')">
                    <!-- data-toggle="collapse" :aria-expanded="buttonUserCoupons === 'white' ? 'true' : 'false'" -->
                    <b-row align-v="center">
                        <b-col cols="3" align="center">
                            <fa icon="tag" size="lg"/>
                        </b-col>
                        <b-col cols="9">
                            <h4 class="mb-0" style="color: black;" :class="{ 'text-blue' : active === 'coupons'}">MOJI KUPONI</h4>
                        </b-col>
                    </b-row>
                </b-card-header>
            </b-card>
        </div>
    </div>

</template>

<script>
    import {BCard, BCardHeader, BRow, BCol} from 'bootstrap-vue'
    export default {
        components: {BCard, BCardHeader, BRow, BCol},
        props: {
            page:{
                type: String,
                required: true
            }
        },
        data() {
            return {
                buttonUserData: '#72A5D8',
                buttonUserOrders: '#e6e7e6',
                buttonUserCoupons: '#e6e7e6',
                active: ''
            }
        },
        watch:{
            //eslint-disable-next-line
            $route: function(to, from) {
                if (to === '/my-profile/my-data') {
                    this.buttonUserData = 'white'
                } else if (to === '/my-profile/my-coupons') {
                    this.buttonUserCoupons = 'white'
                } else if (to === '/moj-profil/moja-narocila') {
                    this.buttonUserOrders = 'white'
                }
            }
        },
        methods: {
            resetAll() {
                this.buttonUserData = '#e6e7e6'
                this.buttonUserCoupons = '#e6e7e6'
                this.buttonUserOrders = '#e6e7e6'
            },
            navigate(ref, color, destination) {
                if (this.$router.currentRoute.name !== destination) {
                    this.resetAll()
                    this[color] = 'white'
                    this.active = destination.replace('my-', '')
                    this.$router.push({name: destination})
                }
            }
        },
        mounted() {
            if (this.$router.currentRoute.fullPath === '/my-profile/my-data') {
                this.buttonUserData = 'white'
                this.active = 'data'
            } else if (this.$router.currentRoute.fullPath === '/my-profile/my-coupons') {
                this.buttonUserCoupons = 'white'
                this.active = 'coupouns'
            } else if (this.$router.currentRoute.fullPath === '/moj-profil/moja-narocila') {
                this.buttonUserOrders = 'white'
                this.active = 'orders'
            }
        }
    }
</script>

<style scoped>
.text-blue{
    color: #72A5D8 !important;
}
.profil-menu .card {
  box-shadow: none !important;
}
</style>

<style>
.profil-menu .profile-no-children div div {
  display: none !important;
}
.profil-menu .card-header::after {
  background-size: 20px !important;
}

.profil-menu .card-header{
    background: white !important;
    padding: 7px !important;
    border-radius: 0px !important;
}
</style>